import * as FDS from "@arctravel/react-fds/lib";

declare const window: any;

const GLOBALDATA = "GLOBAL"
const financialEntity = "THIRDPARTY-FINANCIAL"
const nonFinancialEntity = "THIRDPARTY-NON-FINANCIAL"
const ndcSubscription = "NDC"
const now = new Date();
const aggt = "AGGREGATION"

// String key > value for treeMap
// aliasName & aliasValue for formating payload into same object for different tabs
export const AirlinePerformanceTemplate: any = {
    reportName: "AIRLINE_PERFORMANCE",
    onLoadRun: true,
    info: "",
    lists: [
        {
            label: "Query Filters",
            colorName: "",
            wraps: [{
                name: "airline",
                showName: false,
                excludable: true,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                checks: aggt,
                fields: [{
                    name: "airline",
                    label: "Airline(s)",
                    rLabel: "Airline code",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    minChar: 0,
                    url: window.ENV.references.airline,
                    outputLabel: true,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airline) || '[]'),
                    placeholder: "Enter Airline(s)"
                }]
            }, {
                name: "airline_role",
                showName: false,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                fields: [{
                    name: "airline_role",
                    label: "Specify Airline Type",
                    rLabel: "Specified Airlines Are",
                    help: "Select a single airline type to correspond with the airline criteria entered above.",
                    type: "select",
                    noSelect: true,
                    value: 'dom_mkt_al',
                    options: [
                        { key: "dom_mkt_al", value: "Dominant Marketing Airlines", checked: true },
                        { key: "dom_opt_al", value: "Dominant Operating Airlines", checked: true },
                        { key: "tkt_al", value: "Ticketing Airlines", checked: true },
                        { key: "poo_mkt_al", value: "Point of Origin Marketing Airlines", checked: true },
                        { key: "mkt_al_any_segment", value: "Marketing Airlines on any Segment", checked: true },
                        { key: "opt_al_any_segment", value: "Operating Airlines on any Segment", checked: true }
                    ]
                }]
            }, {
                name: "Trip Origin",
                showName: true,
                type: 'option',
                condition: 'AND',
                fields: [{
                    name: "org",
                    tab: "Airport",
                    label: "By Airport",
                    rLabel: "Trip Origin Codes",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: "ap",
                    minChar: 2,
                    show: true,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.airport,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airport) || '[]'),
                    placeholder: "Enter Origin(s)"
                }, {
                    name: "o_region",
                    tab: "Region",
                    label: "By Region/State",
                    rLabel: "Trip Origin Region",
                    type: "tree",
                    restrictTCs: true,
                    value: [{ code: "US:COUNTRY_CD" }],
                    labelKey: "name",
                    codeKey: "code",
                    color: "r",
                    minChar: 2,
                    show: false,
                    disableFor: { entity: [] },
                    url: window.ENV.references.region,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]'),
                    placeholder: "Enter Origin(s)"
                }, {
                    name: "o_city_cd",
                    tab: "City",
                    label: "By City",
                    rLabel: "Trip Origin City",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: "c",
                    minChar: 1,
                    show: false,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.city,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.city) || '[]'),
                    placeholder: "Enter Origin(s)"
                }]
            }, {
                name: "Trip Destination",
                showName: true,
                type: 'option',
                condition: 'AND',
                fields: [{
                    name: "dst",
                    tab: "Airport",
                    label: "By Airport",
                    rLabel: "Trip Destination Codes",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    minChar: 2,
                    color: 'ap',
                    show: true,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.airport,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.airport) || '[]'),
                    placeholder: "Enter Destination(s)"
                }, {
                    name: "d_region",
                    tab: "Region",
                    label: "By Region/State",
                    rLabel: "Trip Destination Region",
                    type: "tree",
                    restrictTCs: true,
                    value: [{ code: "US:COUNTRY_CD" }],
                    labelKey: "name",
                    codeKey: "code",
                    minChar: 2,
                    color: 'r',
                    show: false,
                    disableFor: { entity: [] },
                    url: window.ENV.references.region,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]'),
                    placeholder: "Enter Destination(s)"
                }, {
                    name: "d_city_cd",
                    tab: "City",
                    label: "By City",
                    rLabel: "Trip Destination City",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    minChar: 1,
                    color: 'c',
                    show: false,
                    disableFor: { entity: [financialEntity] },
                    url: window.ENV.references.city,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.city) || '[]'),
                    placeholder: "Enter Destination(s)"
                }]
            }, {
                name: "countriesOfSale",
                showName: false,
                fields: [{
                    name: "pos_country",
                    label: "Countries Of Sale",
                    rLabel: "Countries of Sale",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [{ code: "US", name: "United States" }],
                    labelKey: "name",
                    codeKey: "code",
                    minChar: 0,
                    disabled: true,
                    enableFor: { dataSource: GLOBALDATA },
                    show: true,
                    url: window.ENV.references.country,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.country) || '[]')
                }]
            }, {
                name: "Markets",
                showName: true,
                help: "Enter each market by concatenating two airport codes, i.e. DCAJFK. Multiple markets can be entered separated by commas.",
                hideFor: { entity: [financialEntity] },
                fields: [{
                    name: "markets",
                    label: "Markets",
                    hideLabel: true,
                    type: "typeahead",
                    matchedOnly: false,
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    minChar: 0,
                    color: 'ap',
                    show: true,
                    store: "groupingsDataRef",
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:apap") || '[]'),
                    canEmpty: true,
                    placeholder: "Enter Market(s)"
                }]
            }, {
                name: "Agency Information",
                help: "Enter complete 8-digit agency or HOL numbers, separated by commas. Leave blank to view agencies located in a city, state, postal code or region.",
                isTitle: true,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                showName: false,
                fields: []
            }, {
                name: "Agency or HOL",
                showName: false,
                type: 'option',
                condition: 'OR',
                disableEmpty: true,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                fields: [{
                    name: "agency",
                    tab: "Agency",
                    label: "By Agency",
                    rLabel: "Agency",
                    type: "typeahead",
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: 'ap',
                    minChar: 0,
                    show: true,
                    outputLabel: true,
                    isAgencyTop: true,
                    options: [],
                    url: window.ENV.references.agency,
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.agency) || '[]'),
                    placeholder: "Enter Agency Code(s) / Group(s)"
                }, {
                    name: "hol",
                    tab: "Home Office Location",
                    label: "By Home Office Location",
                    rLabel: "Home Office Location",
                    type: "typeahead",
                    value: [],
                    labelKey: "description",
                    codeKey: "code",
                    color: 'c',
                    show: false,
                    outputLabel: true,
                    isAgencyTop: true,
                    minChar: 0,
                    options: [],
                    url: window.ENV.references.agency,
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.agency) || '[]'),
                    placeholder: "Enter Home Office Location Code(s) / Group(s)"
                }]
            }, {
                name: "Agency Information",
                showName: false,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                fields: [{
                    name: "agency_city",
                    label: "City",
                    type: "text",
                    value: ""
                }, {
                    name: "agency_postal",
                    label: "Postal Code",
                    type: "text",
                    value: ""
                }]
            }, {
                name: "Agency Region Information",
                showName: false,
                hideFor: { entity: [financialEntity, nonFinancialEntity] },
                fields: [{
                    name: "agency_region",
                    label: "State / Region",
                    type: "tree",
                    value: [],
                    labelKey: "name",
                    codeKey: "code",
                    color: 'r',
                    url: window.ENV.references.region,
                    options: [],
                    localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.region) || '[]')
                }]
            }, {
                name: "Date Information",
                help: "",
                isTitle: true,
                showName: false,
                fields: []
            }, {
                name: "date_type",
                showName: false,
                fields: [{
                    name: "date_type",
                    label: "Date Type",
                    help: "Select either the purchase or travel type to correspond with the date period below.",
                    type: "select",
                    noSelect: true,
                    value: 'travel_date',
                    options: [
                        { key: "travel_date", value: "Travel Date(s)", checked: true },
                        { key: "purchase_date", value: "Purchase Date(s)", checked: true }
                    ]
                }]
            }, {
                name: "prior_period",
                showName: false,
                fields: [{
                    name: "prior_period",
                    label: "Prior Period",
                    type: "select",
                    noSelect: true,
                    value: "Y",
                    options: [
                        { key: 'Y', value: 'Year', checked: true },
                        { key: 'M', value: 'Month', checked: true },
                        { key: 'W', value: 'Week', checked: true }
                    ],
                    help: "Weekly Prior Period enables By Weekly Report Period only; Monthly Prior period enables By Monthly and By Weekly Report Periods only; and Year Prior Period enables all Report Period selections."
                }]
            }, {
                name: "Report Period",
                help: "FROM date should be previous or current to the TO date. Report Period enables when correct Prior Period is selected.",
                showName: true,
                type: 'option',
                condition: 'OR',
                fields: [{
                    name: "day_report_period",
                    tab: "By Day",
                    label: "By Day or Range",
                    hLabel: "Travel Period (D)",
                    type: "date",
                    selectsRange: true,
                    disabled: false,
                    startDate: [FDS.dateToText(new Date(), "/", true, false)],
                    endDate: [FDS.dateToText(new Date(), "/", true, false)],
                    minDate: "01/01/2022",
                    show: false
                }, {
                    name: "week_report_period",
                    tab: "By Week",
                    label: "By Week",
                    type: "date",
                    subType: "week",
                    selectsRange: true,
                    startDate: [FDS.dateToText(new Date(now.setDate(now.getDate() - 6)), "/", true, false)],
                    endDate: [FDS.dateToText(new Date(), "/", true, false)],
                    minDate: "01/01/2022",
                    show: true
                }, {
                    name: "month_report_period",
                    tab: "By Month",
                    label: "By Month or Range",
                    type: "monthYearPicker",
                    minDate: "01/01/2022",
                    selectsRange: true,
                    disabled: false,
                    startDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                    endDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                    show: false
                }, {
                    name: "year_report_period",
                    tab: "By Year",
                    label: "By Year",
                    type: "yearPicker",
                    minDate: "01/01/2022",
                    disabled: false,
                    startDate: [new Date().getFullYear() + ""],
                    endDate: [],
                    show: false
                }, {
                    name: "summary_report_period",
                    tab: "12M Sum",
                    label: "12 Month Summary Ending",
                    type: "monthYearPicker",
                    disabled: false,
                    startDate: [(new Date().getMonth() + 1 + "/" + new Date().getFullYear())],
                    endDate: [''],
                    minDate: "01/01/2022",
                    show: false
                }]
            }, {
                name: "advance_purchase_range",
                showName: false,
                fields: [{
                    name: "advance_purchase_range",
                    label: "Advance Purchase Range",
                    type: "select",
                    noSelect: true,
                    value: 'all',
                    options: [
                        { key: "all", value: "All", checked: true },
                        { key: "0-2", value: "0-2 days", checked: true },
                        { key: "3-6", value: "3-6 days", checked: true },
                        { key: "7-13", value: "7-13 days", checked: true },
                        { key: "14-20", value: "14-20 days", checked: true },
                        { key: "21-30", value: "21-30 days", checked: true },
                        { key: "31-60", value: "31-60 days", checked: true },
                        { key: "60", value: ">60 days", checked: true },
                    ]
                }]
            }, {
                name: "Service Class",
                showName: false,
                fields: [{
                    name: "cabin_class",
                    label: "Service Class",
                    type: "typeahead",
                    matchedOnly: true,
                    selectAll: true,
                    value: [],
                    minChar: "0",
                    labelKey: "value",
                    codeKey: "key",
                    options: [
                        { key: "First Class", value: "First Class", checked: true },
                        { key: "Business Class", value: "Business Class", checked: true },
                        { key: "Premium/Full Economy Class", value: "Premium/Full Economy Class", checked: true },
                        { key: "Discount Economy Class", value: "Discount Economy Class", checked: true },
                        { key: "Other Classes", value: "Other Classes", checked: true }
                    ],
                    placeholder: "Enter Service Class(s)"
                }]
            }, {
                name: "Dominant Ticketing Designator",
                showName: false,
                fields: [{
                    name: "trip_dom_rbkd",
                    label: "Dominant Ticketing Designator",
                    rLabel: "Dominant Ticketing Designator",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    minChar: "0",
                    labelKey: "value",
                    codeKey: "key",
                    selectAll: true,
                    options: [
                        { key: "A", value: "A", checked: true },
                        { key: "B", value: "B", checked: true },
                        { key: "C", value: "C", checked: true },
                        { key: "D", value: "D", checked: true },
                        { key: "E", value: "E", checked: true },
                        { key: "F", value: "F", checked: true },
                        { key: "G", value: "G", checked: true },
                        { key: "H", value: "H", checked: true },
                        { key: "I", value: "I", checked: true },
                        { key: "J", value: "J", checked: true },
                        { key: "K", value: "K", checked: true },
                        { key: "L", value: "L", checked: true },
                        { key: "M", value: "M", checked: true },
                        { key: "N", value: "N", checked: true },
                        { key: "O", value: "O", checked: true },
                        { key: "P", value: "P", checked: true },
                        { key: "Q", value: "Q", checked: true },
                        { key: "R", value: "R", checked: true },
                        { key: "S", value: "S", checked: true },
                        { key: "T", value: "T", checked: true },
                        { key: "U", value: "U", checked: true },
                        { key: "V", value: "V", checked: true },
                        { key: "W", value: "W", checked: true },
                        { key: "X", value: "X", checked: true },
                        { key: "Y", value: "Y", checked: true },
                        { key: "Z", value: "Z", checked: true }
                    ],
                    placeholder: "Enter Designator(s)"
                }]
            }, {
                name: "directionality",
                showName: false,
                fields: [{
                    name: "directionality",
                    label: "Directionality",
                    help: 'Select "Directional" to limit output to one direction, from origin(s) to destination(s). Select "Bi-directional" to display output for both directions of travel separately, or “Bi-directional Total” to sum both directions together. Select "Non-directional" to display the average from both directions of travel into one output row.',
                    type: "select",
                    noSelect: true,
                    value: 'BI_DIRECTIONAL',
                    options: [
                        { key: "DIRECTIONAL", value: "Directional", checked: true },
                        { key: "NON_DIRECTIONAL", value: "Non-Directional", checked: true },
                        { key: "BI_DIRECTIONAL", value: "Bi-Directional", checked: true },
                        { key: "BI_DIRECTIONAL_TOTAL", value: "Bi-Directional Total", checked: true },
                    ]
                }]
            }, {
                name: "Transaction Type",
                showName: false,
                fields: [{
                    name: "transaction_code",
                    label: "Transaction Type",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [],
                    minChar: "0",
                    labelKey: "value",
                    codeKey: "key",
                    selectAll: true,
                    options: [
                        { key: "E", value: "Exchange", checked: true },
                        { key: "O", value: "Old Ticket", checked: true, enableFor: { entity: [] } },
                        { key: "V", value: "Void", checked: true, enableFor: { dataSource: GLOBALDATA } },
                        { key: "R", value: "Refund", checked: true },
                        { key: "I", value: "Sales", checked: true }
                    ],
                    placeholder: "Enter Transaction Type(s)"
                }]
            }, {
                name: "ndc",
                showName: false,
                showFor: { subscription: ndcSubscription },
                checks: ndcSubscription,
                fields: [{
                    name: "ndc",
                    label: "NDC Indicator",
                    help: 'Include or exclude NDC Indicator.',
                    type: "select",
                    noSelect: false,
                    value: "",
                    options: [
                        { key: "Y", value: "Yes", checked: true },
                        { key: "N", value: "No", checked: true }
                    ]
                }]
            }, {
                name: "data_columns_to_report",
                showName: false,
                show: false,
                fields: [{
                    name: "data_columns_to_report",
                    label: "Data Columns To Report",
                    show: false,
                    type: "hidden",
                    value: 'FULL',
                    options: [{ key: "FULL", value: "FULL" }]
                }]
            }, {
                name: "Data Source",
                showName: false,
                fields: [{
                    name: "source",
                    label: "Data Source",
                    type: "typeahead",
                    matchedOnly: true,
                    value: [{ code: "1", description: "ASP Settled Data" }],
                    labelKey: "description",
                    codeKey: "code",
                    hideKey: true,
                    required: true,
                    minChar: 0,
                    disabled: true,
                    enableFor: { dataSource: GLOBALDATA },
                    show: true,
                    selectAll: true,
                    options: [
                        { code: "1", description: "ASP Settled Data" },
                        { code: "2", description: "Contributed Direct" },
                        { code: "3", description: "BSP Settled Data" },
                        { code: "4", description: "Contributed Indirect" }
                    ],
                    placeholder: "Select Data Source(s)"
                }]
            }]
        }
    ],
    chartsLists: [
        {
            label: "Summary",
            wraps: [{
                name: "performance_metrics",
                label: "Performance Metrics",
                showName: true,
                fields: [{
                    name: "summary",
                    label: "Summary",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "SummaryCards",
                    keys: {
                        label: "summary",
                        values: [
                            "blended_pay",
                            "trip",
                            "taxes",
                            "commission_percentage"
                        ],
                        vLabels: ["Sales", "O&D Count", "Taxes", "Average Commission"],
                        symbol: ["$", "", "$", "%"]
                    }
                }]
            }]
        }, {
            label: "Total Sales",
            tab: true,
            wraps: [{
                name: "saleTrends",
                label: "Sale Trends",
                showName: true,
                fields: [{
                    name: "agencyHomeOffice",
                    label: "Agency Home Office",
                    help: "",
                    type: "TreemapChart",
                    enableValues: true,
                    theme: { colors: ["#59CADD", "#D2F2F7"] },
                    keys: {
                        label: "hol",
                        value: "payment_share",
                        sort: "payment_share",
                        past: "payment_share_prior",
                        legends: "Sales Agency Home Office",
                        symbol: "%"
                    },
                    spacing: 4
                }, {
                    name: "agency",
                    label: "Agency",
                    help: "",
                    type: "TreemapChart",
                    enableValues: true,
                    theme: { colors: ["#75E6DA", "#C7E2DF"] },
                    keys: {
                        label: "agency",
                        value: "payment_share",
                        sort: "payment_share",
                        past: "payment_share_prior",
                        legends: "Agency Sales",
                        symbol: "%"
                    },
                    spacing: 4
                }, {
                    name: "transactionType",
                    label: "Transaction Type",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "DonutChart",
                    showTotal: true,
                    totalText: "Transactions",
                    theme: { colors: ['#189BB0', "#75E6DA", "#D4F1F4"] },
                    keys: {
                        label: "transaction",
                        value: "count",
                        symbol: "",
                        sort: ""
                    },
                    spacing: 4
                }]
            }, {
                name: "topPerformers",
                label: "Top Performers",
                showName: true,
                fields: [{
                    name: "topAgencyMetrics",
                    label: "Top Agency Sales",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Sales", y: "Agency" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "agency",
                        values: ["blended_pay"],
                        past: ["blended_pay_prior"],
                        legends: ["Total Sales Amount", "Total Sales Amount - Prior"],
                        symbol: "$",
                        sort: ""
                    },
                    spacing: 4
                }, {
                    name: "topAgencyCommission",
                    label: "Top Agency Commission",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Commissions", y: "Agency" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "agency",
                        values: ["commission"],
                        past: ["commission_prior"],
                        legends: ["Total Commissions", "Total Commissions - Prior"],
                        symbol: "$",
                        sort: "commission"
                    },
                    spacing: 4
                }, {
                    name: "trends",
                    label: "Airline Sales Trends",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "XYChart",
                    enableLines: true,
                    enableDots: true,
                    enableArea: true,
                    enableValues: true,
                    disableValuesForIndexes: [1],
                    labels: { x: "Timeframe", y: "Revenue" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "timeframe",
                        values: ["blended_pay"],
                        past: ["blended_pay_prior"],
                        legends: ["Total Sales Amount", "Total Sales Amount - Prior"],                        
                        symbol: "$",
                        sort: "blended_pay"
                    },
                    spacing: 4
                }]
            }, {
                name: "topPerformers",
                label: "Top Performers",
                showName: false,
                fields: [{
                    name: "topOrigin",
                    label: "Top Origin Sales",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Sales", y: "Origin" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "org",
                        values: ["blended_pay"],
                        past: ["blended_pay_prior"],
                        legends: ["Total Sales Amount", "Total Sales Amount - Prior"],
                        symbol: "$",
                        sort: "blended_pay"
                    },
                    spacing: 4
                }, {
                    name: "topDestination",
                    label: "Top Destination Sales",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Sales", y: "Destination" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "dst",
                        values: ["blended_pay"],
                        past: ["blended_pay_prior"],
                        legends: ["Total Sales Amount", "Total Sales Amount - Prior"],
                        symbol: "$",
                        sort: "blended_pay"
                    },
                    spacing: 4
                }, {
                    name: "byClass",
                    label: "Sales by Class",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labelIndex: true,
                    labels: { x: "Sales", y: "Class" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "cabin_class",
                        values: ["blended_pay"],
                        past: ["blended_pay_prior"],
                        legends: ["Total Sales Amount", "Total Sales Amount - Prior"],
                        symbol: "$",
                        sort: "blended_pay"
                    },
                    spacing: 4
                }, {
                    name: "ndc",
                    label: "NDC Share",
                    help: "",
                    type: "XYChart",
                    enableLines: true,
                    enableDots: true,
                    enableArea: true,
                    enableValues: true,
                    showFor: { subscription: ndcSubscription },
                    disableValuesForIndexes: [1],
                    labels: { x: "Timeframe", y: "Share %" },
                    keys: {
                        label: "timeframe",
                        values: ["ndc_share"],
                        past: ["ndc_share_prior"],
                        legends: ["NDC Share", "NDC Share - Prior"],
                        headers: {
                            ndc_share: "NDC Share"
                        },
                        symbol: "%"
                    },
                    spacing: 12
                }]
            }]
        }, {
            label: "Base Fares",
            tab: true,
            wraps: [{
                name: "baseFareTrends",
                label: "Base Fare Trends",
                showName: true,
                fields: [{
                    name: "agencyHomeOffice",
                    aliasName: "agencyHomeOfficeBaseFare",
                    label: "Agency Home Office",
                    help: "",
                    type: "TreemapChart",
                    enableValues: true,
                    theme: { colors: ["#FFDDDD", "#FFF2F2"] },
                    keys: {
                        label: "hol",
                        aliasValue: "revenue_share",
                        sort: "revenue_share",
                        past: "revenue_share_prior",
                        legends: "Base Fare Agency Home Office",
                        symbol: "%"
                    },
                    spacing: 4
                }, {
                    name: "agency",
                    aliasName: "agencyBaseFare",
                    label: "Agency",
                    help: "",
                    type: "TreemapChart",
                    enableValues: true,
                    theme: { colors: ["#FFEDD0", "#FFFAF2"] },
                    keys: {
                        label: "agency",
                        aliasValue: "revenue_share",
                        sort: "revenue_share",
                        past: "revenue_share_prior",
                        legends: "Base Fare Agency",
                        symbol: "%"
                    },
                    spacing: 4
                }, {
                    name: "transactionType",
                    label: "Transaction Type",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "DonutChart",
                    showTotal: true,
                    totalText: "Transactions",
                    theme: { colors: ['#F5A623', "#FFEDD0", "#FFFAF2"] },
                    keys: {
                        label: "transaction",
                        value: "count",
                        symbol: "",
                        sort: ""
                    },
                    spacing: 4
                }]
            }, {
                name: "topPerformersRevenue",
                label: "Top Performers",
                showName: true,
                fields: [{
                    name: "topAgencyMetrics",
                    aliasName: "topAgencyBaseFare",
                    label: "Top Agency Base Fare",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Base Fare", y: "Agency" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "agency",
                        aliasValue: ["blended_fare"],
                        past: ["blended_fare_prior"],
                        legends: ["Total Base Fare Amount", "Total Base Fare Amount - Prior"],
                        symbol: "$",
                        sort: "blended_fare"
                    },
                    spacing: 4
                }, {
                    name: "topAgencyCommission",
                    label: "Top Agency Commission",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Commissions", y: "Agency" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "agency",
                        values: ["commission"],
                        past: ["commission_prior"],
                        legends: ["Total Commissions", "Total Commissions - Prior"],
                        symbol: "$",
                        sort: "commission"
                    },
                    spacing: 4
                }, {
                    name: "trends",
                    aliasName: "baseFaretrends",
                    label: "Airline Base Fare Trends",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "XYChart",
                    enableLines: true,
                    enableDots: true,
                    enableArea: true,
                    enableValues: true,
                    disableValuesForIndexes: [1],
                    labels: { x: "Timeframe", y: "Base Fare" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "timeframe",
                        aliasValue: ["blended_fare"],
                        past: ["blended_fare_prior"],
                        legends: ["Total Base Fare Amount", "Total Base Fare Amount - Prior"],
                        symbol: "$",
                        sort: "blended_fare"
                    },
                    spacing: 4
                }]
            }, {
                name: "topPerformersRevenue",
                label: "Top Performers",
                showName: false,
                fields: [{
                    name: "topOrigin",
                    aliasName: "topOriginBaseFare",
                    label: "Top Origin Fare",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Fare", y: "Origin" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "org",
                        aliasValue: ["blended_fare"],
                        past: ["blended_fare_prior"],
                        legends: ["Total Base Fare Amount", "Total Base Fare Amount - Prior"],
                        symbol: "$",
                        sort: "blended_fare"
                    },
                    spacing: 4
                }, {
                    name: "topDestination",
                    aliasName: "topDestinationBaseFare",
                    label: "Top Destination Base Fare",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Base Fare", y: "Destination" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "dst",
                        aliasValue: ["blended_fare"],
                        past: ["blended_fare_prior"],
                        legends: ["Total Base Fare Amount", "Total Base Fare Amount - Prior"],
                        symbol: "$",
                        sort: "blended_fare"
                    },
                    spacing: 4
                }, {
                    name: "byClass",
                    aliasName: "baseFareByService",
                    label: "Base Fare by Class",
                    labelIndex: true,
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Base Fare", y: "Class" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "cabin_class",
                        aliasValue: ["blended_fare"],
                        past: ["blended_fare_prior"],
                        legends: ["Total Base Fare Amount", "Total Base Fare Amount - Prior"],
                        symbol: "$",
                        sort: "blended_fare"
                    },
                    spacing: 4
                }]
            }]
        }, {
            label: "Net Fare",
            tab: true,
            wraps: [{
                name: "netFareTrends",
                label: "Net Fare Trends",
                showName: true,
                fields: [{
                    name: "agencyHomeOffice",
                    aliasName: "agencyHomeOfficeNetFare",
                    label: "Agency Home Office",
                    help: "",
                    type: "TreemapChart",
                    enableValues: true,
                    theme: { colors: ['#C7E3FF', "#EAF4FF"] },
                    keys: {
                        label: "hol",
                        aliasValue: "net_revenue_share",
                        sort: "net_revenue_share",
                        past: "net_revenue_share_prior",
                        legends: "Net Fare Agency Home Office",
                        symbol: "%"
                    },
                    spacing: 4
                }, {
                    name: "agency",
                    aliasName: "agencyNetFare",
                    label: "Agency",
                    help: "",
                    type: "TreemapChart",
                    enableValues: true,
                    theme: { colors: ["#44A3FF", "#91C9FF"] },
                    keys: {
                        label: "agency",                        
                        aliasValue: "net_revenue_share",
                        sort: "net_revenue_share",
                        past: "net_revenue_share_prior",
                        legends: "Net Fare Agency",
                        symbol: "%"
                    },
                    spacing: 4
                }, {
                    name: "transactionType",
                    label: "Transaction Type",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "DonutChart",
                    showTotal: true,
                    totalText: "Transactions",
                    theme: { colors: ['#244D89', "#0060F0", "#91C9FF"] },
                    keys: {
                        label: "transaction",
                        value: "count",
                        symbol: "",
                        sort: ""
                    },
                    spacing: 4
                }]
            }, {
                name: "topPerformersRevenue",
                label: "Top Performers",
                showName: true,
                fields: [{
                    name: "topAgencyMetrics",
                    aliasName: "topAgencyNetFare",
                    label: "Top Agency Net Fare",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Net Fare", y: "Agency" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "agency",
                        aliasValue: ["avg_net_revenue"],
                        past: ["avg_net_revenue_prior"],
                        legends: ["Total Net Fare Amount", "Total Net Fare Amount - Prior"],
                        symbol: "$",
                        sort: "avg_net_revenue"
                    },
                    spacing: 4
                }, {
                    name: "topAgencyCommission",
                    label: "Top Agency Commission",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Commissions", y: "Agency" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "agency",
                        values: ["commission"],
                        past: ["commission_prior"],
                        legends: ["Total Commissions", "Total Commissions - Prior"],
                        symbol: "$",
                        sort: "commission"
                    },
                    spacing: 4
                }, {
                    name: "trends",
                    aliasName: "netFaretrends",
                    label: "Airline Net Fare Trends",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "XYChart",
                    enableLines: true,
                    enableDots: true,
                    enableArea: true,
                    enableValues: true,
                    disableValuesForIndexes: [1],
                    labels: { x: "Timeframe", y: "Net Fare" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "timeframe",
                        aliasValue: ["avg_net_revenue"],
                        past: ["avg_net_revenue_prior"],
                        legends: ["Total Net Fare Amount", "Total Net Fare Amount - Prior"],
                        symbol: "$",
                        sort: "avg_net_revenue"
                    },
                    spacing: 4
                }]
            }, {
                name: "topPerformersRevenue",
                label: "Top Performers",
                showName: false,
                fields: [{
                    name: "topOrigin",
                    aliasName: "topOriginNetFare",
                    label: "Top Origin Net Fare",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Net Fare", y: "Origin" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "org",
                        aliasValue: ["avg_net_revenue"],
                        past: ["avg_net_revenue_prior"],
                        legends: ["Total Net Fare Amount", "Total Net Fare Amount - Prior"],
                        symbol: "$",
                        sort: "avg_net_revenue"
                    },
                    spacing: 4
                }, {
                    name: "topDestination",
                    aliasName: "topDestinationNetFare",
                    label: "Top Destination Net Fare",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Net Fare", y: "Destination" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "dst",
                        aliasValue: ["avg_net_revenue"],
                        past: ["avg_net_revenue_prior"],
                        legends: ["Total Net Fare Amount", "Total Net Fare Amount - Prior"],
                        symbol: "$",
                        sort: "avg_net_revenue"
                    },
                    spacing: 4
                }, {
                    name: "byClass",
                    aliasName: "netFareByService",
                    label: "Net Fare by Class",
                    labelIndex: true,
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Net Fare", y: "Class" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "cabin_class",
                        aliasValue: ["avg_net_revenue"],
                        past: ["avg_net_revenue_prior"],
                        legends: ["Total Net Fare Amount", "Total Net Fare Amount - Prior"],
                        symbol: "$",
                        sort: "avg_net_revenue"
                    },
                    spacing: 4
                }]
            }]
        }, {
            label: "Taxes",
            tab: true,
            wraps: [{
                name: "taxes",
                label: "Taxes",
                showName: true,
                fields: [{
                    name: "agencyHomeOffice",
                    aliasName: "agencyHomeOfficetaxes",
                    label: "Agency Home Office",
                    help: "",
                    type: "TreemapChart",
                    enableValues: true,
                    theme: { colors: ['#B0D70C', "#B0D70C"] },
                    keys: {
                        label: "hol",
                        aliasValue: "taxes_share",
                        sort: "taxes_share",
                        past: "taxes_share_prior",
                        legends: "Taxes Agency Home Office",
                        symbol: "%"
                    },
                    spacing: 4
                }, {
                    name: "agency",
                    aliasName: "agencyTaxes",
                    label: "Agency",
                    help: "",
                    type: "TreemapChart",
                    enableValues: true,
                    theme: { colors: ["#B1CF6D", "#DFEEBE"] },
                    keys: {
                        label: "agency",
                        aliasValue: "taxes_share",
                        sort: "taxes_share",
                        past: "taxes_share_prior",
                        legends: "Agency Taxes",
                        symbol: "%"
                    },
                    spacing: 4
                }, {
                    name: "transactionType",
                    label: "Transaction Type",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "DonutChart",
                    showTotal: true,
                    totalText: "Transactions",
                    theme: { colors: ['#297F2B', "#B1CF6D", "#DFEEBE"] },
                    keys: {
                        label: "transaction",
                        value: "count",
                        symbol: "",
                        sort: ""
                    },
                    spacing: 4
                }]
            }, {
                name: "topPerformersRevenue",
                label: "Top Performers",
                showName: true,
                fields: [{
                    name: "topAgencyMetrics",
                    aliasName: "topAgencyTaxes",
                    label: "Top Agency Taxes",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Taxes", y: "Agency" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "agency",
                        aliasValue: ["taxes"],
                        past: ["taxes_prior"],
                        legends: ["Total Taxes Amount", "Total Taxes Amount - Prior"],
                        symbol: "$",
                        sort: "taxes"
                    },
                    spacing: 4
                }, {
                    name: "topAgencyCommission",
                    label: "Top Agency Commission",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Commissions", y: "Agency" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "agency",
                        values: ["commission"],
                        past: ["commission_prior"],
                        legends: ["Total Commissions", "Total Commissions - Prior"],
                        symbol: "$",
                        sort: "commission"
                    },
                    spacing: 4
                }, {
                    name: "trends",
                    aliasName: "Taxestrends",
                    label: "Airline Taxes Trends",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "XYChart",
                    enableLines: true,
                    enableDots: true,
                    enableArea: true,
                    enableValues: true,
                    disableValuesForIndexes: [1],
                    labels: { x: "Timeframe", y: "Taxes" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "timeframe",
                        aliasValue: ["taxes"],
                        past: ["taxes_prior"],
                        legends: ["Total Taxes Amount", "Total Taxes Amount - Prior"],
                        symbol: "$",
                        sort: "taxes"
                    },
                    spacing: 4
                }]
            }, {
                name: "topPerformersRevenue",
                label: "Top Performers",
                showName: false,
                fields: [{
                    name: "topOrigin",
                    aliasName: "topOriginTaxes",
                    label: "Top Origin Taxes",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Taxes", y: "Origin" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "org",
                        aliasValue: ["taxes"],
                        past: ["taxes_prior"],
                        legends: ["Total Taxes Amount", "Total Taxes Amount - Prior"],
                        symbol: "$",
                        sort: "taxes"
                    },
                    spacing: 4
                }, {
                    name: "topDestination",
                    aliasName: "topDestinationTaxes",
                    label: "Top Destination Taxes",
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Taxes", y: "Destination" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "dst",
                        aliasValue: ["taxes"],
                        past: ["taxes_prior"],
                        legends: ["Total Taxes Amount", "Total Taxes Amount - Prior"],
                        symbol: "$",
                        sort: "taxes"
                    },
                    spacing: 4
                }, {
                    name: "byClass",
                    aliasName: "TaxesByService",
                    label: "Taxes by Class",
                    labelIndex: true,
                    help: "Enter one or more airlines for querying using name, 2-digit alpha or 3-digit numeric codes separated by commas. This field can be left blank for all airline results.",
                    type: "BarChart",
                    orientation: "H",
                    merged: true,
                    labels: { x: "Taxes", y: "Class" },
                    theme: { colors: ['#189BB0', "#78dded"] },
                    keys: {
                        label: "cabin_class",
                        aliasValue: ["taxes"],
                        past: ["taxes_prior"],
                        legends: ["Total Taxes Amount", "Total Taxes Amount - Prior"],
                        symbol: "$",
                        sort: "taxes"
                    },
                    spacing: 4
                }]
            }]
        }]
};